import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../../shared/services/user/user-service.service';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(
                private router: Router, 
                private userService: UserService) {}

    async canActivate() {
        let base = this.userService.getDataBase();
        let userData = await this.userService.getUserFromDataBase();
        if (userData && base) {
            return true
        } else {
            this.userService.logout();
            this.router.navigate(['/login']);
            return false;
        }
    }
}
