import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';

import * as env from "../../../../environments/environment";
import * as moment from 'moment';
import { FunctionsService } from '../utilsFunctions/functions.service';

@Injectable({
  providedIn: 'root'
})
export class CacheService {
  public openedOSType = 'opened-os';
  public closedOSType = 'closed-os';
  public openedOrcType = 'opened-orc';
  public closedOrcType = 'closed-orc';
  public stockType = 'stock';
  customerType = 'customer';
  prospectType = 'prospect'

  constructor(private http: Http,
              private router: Router,
              private functions: FunctionsService) { }


  async checkInvalidCache(type, hashCache) {
    let baseCache = moment(await this.getCacheHash(type));
    if (!baseCache.isValid()) {
      return true;
    }

    let localCache = moment(hashCache);
    return !localCache.isSame(baseCache);
  }

  async invalidateCache(type) {
    // Quando um registro for salvo, deve salvar a data que foi salvo para invalidar o cachê das outras sessões
    let token = await this.functions.getUserToken();
    let now = moment();
    await this.http.patch(`${this.functions.getBaseURL()}/dados/cache/${type}/.json?auth=${token}`, {date: now.toISOString()}).toPromise();
  }

  async getCacheHash(type) {
    let token = await this.functions.getUserToken();
    let cacheData = await this.http.get(`${this.functions.getBaseURL()}/dados/cache/${type}/.json?auth=${token}`).toPromise();
    cacheData = JSON.parse(cacheData['_body']);

    if (cacheData) {
      return cacheData['date'];
    } else {
      return '';
    }
  }

  getDataBase() {
    let base = JSON.parse(localStorage.getItem("system-base"));
    if (!base) {
      this.router.navigate(['/login']);
      return
    }

    return base.base;
  }
}
