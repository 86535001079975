import { HttpLoaderService } from './http-loader.service';
import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpHandler,
} from '@angular/common/http';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {

    public static emited = 0

    constructor(private httpLoaderService: HttpLoaderService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        HttpConfigInterceptor.emited++;
        this.httpLoaderService.setLoader(HttpConfigInterceptor.emited > 0);
        return next.handle(request).pipe(
            finalize(() => {
                HttpConfigInterceptor.emited--;
                this.httpLoaderService.setLoader(HttpConfigInterceptor.emited > 0);
            })
        );
    }
}
