// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCuJPu6oesJh4WN8aez6kFPu38z6Nho3YA",
    authDomain: "agb-os.firebaseapp.com",
    databaseURL: "https://agb-os.firebaseio.com",
    baseURL: "https://###.firebaseio.com",
    projectId: "agb-os",
    storageBucket: "agb-os.appspot.com",
    messagingSenderId: "709138017526"
  },
  pushApi: 'https://2w5zco32p2.execute-api.sa-east-1.amazonaws.com'
};

export const url_firebase = environment.firebase.baseURL;
// databaseURL: "https://agb-os-dev.firebaseio.com",
