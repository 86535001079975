import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Http } from '@angular/http';

import * as env from "../../../../environments/environment";
import { FunctionsService } from '../utilsFunctions/functions.service';
import { ConfigService } from '../config/config.service';
import { Cliente } from '../../../classes/cliente';
import { CacheService } from '../cache/cache.service';
import { LogService } from '../logs/log.service';
import { isUndefined } from 'util';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  private clientes: any[] = [];
  private prospect: any[] = [];
  customerCacheHash= '';
  prospectCacheHash
  opcaoLocal;
  opcaoStatus;
  opcaoSegmento;
  opcaoSubSegmento;

  constructor(
      private functions: FunctionsService,
      private configService: ConfigService,
      private http: Http,
      private router: Router,
      private logServ: LogService,
      private cacheService: CacheService
  ) { }

  clearList() {
    this.clientes = [];
    this.prospect = [];
  }

  getDataBase() {
    let base = JSON.parse(localStorage.getItem("system-base"));
    if (!base) {
      this.router.navigate(['/login']);
      return
    }

    return base.base;
  }

  async importData() {
    /*let token = await this.functions.getUserToken();
  

    //this.http.delete(`${this.functions.getBaseURL()}/dados/clientes/.json?auth=${token}`).toPromise();


    //let dataCustomer = await this.loadCustomer();
    //console.log(dataCustomer);
      let cliente = new Cliente();
      cliente.prospect = false;
      cliente.codCliente = 1;
      cliente.nomeFantasia = 'AVIC DISTRIBUIDORA DE ACUMULADORES LTDA';
      cliente.razaoSocial = 'Bateria Moura'
      cliente.tipoPessoa = "PJ";
      cliente.telefone = '4133019500';
      cliente.email = 'mkt@mourapr.com.br';
      cliente.cidade = 'Curitiba';
      cliente['estado'] = 'PR';
      this.http.post(`${this.functions.getBaseURL()}/dados/clientes/.json?auth=${token}`, cliente).toPromise();



    let data = await this.http.get('http://localhost:4200/assets/clientes.csv').toPromise();
    let lines = data['_body'].split('\r\n');
    for (let i = 3501; i < 3813; i++) {
      let cliente = new Cliente();
      cliente.prospect = false;
      cliente.codCliente = i + 10;

      let line = lines[i].split(';');
      if (line[19]) {
        cliente.nomeFantasia = line[19];
        if (line[20].length == 11) {
          cliente.tipoPessoa = "PF";
          cliente['CPF'] = line[20];
        } else {
          cliente.tipoPessoa = "PJ";
          cliente.CNPJ = line[20];
        }
        cliente.email = line[21];
        if (line[23].length == 8) {
          cliente.telefone = line[22] + line[23];
        } else {
          cliente.celular = line[22] + line[23];
        }
      } else {
        console.log(line);
      }

      this.http.post(`${this.functions.getBaseURL()}/dados/clientes/.json?auth=${token}`, cliente).toPromise();
      //dataCustomer.push();
    }

*/

  }

  async loadBoth() {
    let allCustomer = [];
    allCustomer = await this.loadCustomer();
    allCustomer = allCustomer.concat(await this.loadProspect());


    allCustomer.sort((a,b) => {
      if (a.nomeFantasia > b.nomeFantasia) {
        return 1
      } else {
        if (a.nomeFantasia < b.nomeFantasia) {
          return -1
        } else {
          return 0;
        }
      }
    });

    return allCustomer;
  }

  async loadCustomer() {
    if (this.clientes.length == 0 || await this.cacheService.checkInvalidCache(this.cacheService.customerType, this.customerCacheHash)) {
      let token = await this.functions.getUserToken();
    
      let data = await this.http.get(`${this.functions.getBaseURL()}/dados/clientes/.json?auth=${token}`).toPromise();
      this.clientes = this.functions.convertObjToArray(data);
      this.customerCacheHash =  await this.cacheService.getCacheHash(this.cacheService.customerType);
      
      this.clientes.sort((a,b) => {
        if (a.nomeFantasia > b.nomeFantasia) {
          return 1
        } else {
          if (a.nomeFantasia < b.nomeFantasia) {
            return -1
          } else {
            return 0;
          }
        }
      });
    }



    return Object.assign([], this.clientes);
  }

  async saveCustomer(customerData) {

    let token = await this.functions.getUserToken();
  

    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/clientes/${customerData.id}/.json?auth=${token}`).toPromise();
    if (data["_body"] == 'null') {
      if (customerData.id) {
        await this.removeProspect(customerData, false);
      } else {
        customerData.codCliente = await this.configService.getNewLastCustomerCode();
        customerData.dataDeCadastro = Date.now();
      }
      if (customerData.id) {
        await this.http.put(`${this.functions.getBaseURL()}/dados/clientes/${customerData.id}/.json?auth=${token}`, customerData).toPromise();
        this.cacheService.invalidateCache(this.cacheService.customerType);
        this.logServ.addLog({...customerData, codigo: customerData.codCliente}, 'Criou', 'Cliente');
        this.clearList();
      } else {
        let resp = await this.http.post(`${this.functions.getBaseURL()}/dados/clientes/.json?auth=${token}`, customerData).toPromise();
        console.log(resp);
        console.log(JSON.parse(resp['_body']).name)
        let idCustomerData = JSON.parse(resp['_body']).name
        this.cacheService.invalidateCache(this.cacheService.prospectType);
        this.logServ.addLog({...customerData, codigo: customerData.codCliente, id: idCustomerData}, 'Criou', 'Cliente');
        this.clearList();
        return {codigo: customerData.codCliente, id: idCustomerData};
      }
    } else {
      await this.http.put(`${this.functions.getBaseURL()}/dados/clientes/${customerData.id}/.json?auth=${token}`, customerData).toPromise();
      this.cacheService.invalidateCache(this.cacheService.customerType);
      this.logServ.addLog({...customerData, codigo: customerData.codCliente}, 'Alterou', 'Cliente');
      this.clearList();
    }
    return {codigo: customerData.codCliente, id: customerData.id};
  }

  async removeCustomer(customerData, createLog= true) {
    if (customerData && customerData.id) {
      let token = await this.functions.getUserToken();
    
      await this.http.delete(`${this.functions.getBaseURL()}/dados/clientes/${customerData.id}/.json?auth=${token}`).toPromise();
      if(createLog){
      this.logServ.addLog({...customerData, codigo: customerData.codCliente}, 'Deletou', 'Cliente');
      }
      this.clearList();
      this.cacheService.invalidateCache(this.cacheService.customerType);

    }
  }

  async loadProspect() {
    if (this.prospect.length == 0 || await this.cacheService.checkInvalidCache(this.cacheService.prospectType, this.prospectCacheHash)) {
      let token = await this.functions.getUserToken();
    
      let data = await this.http.get(`${this.functions.getBaseURL()}/dados/prospect/.json?auth=${token}`).toPromise();
      this.prospect = this.functions.convertObjToArray(data);
      this.prospectCacheHash =  await this.cacheService.getCacheHash(this.cacheService.prospectType);
    }

    this.prospect.sort((a,b) => {
      if (a.nomeFantasia > b.nomeFantasia) {
        return 1
      } else {
        if (a.nomeFantasia < b.nomeFantasia) {
          return -1
        } else {
          return 0;
        }
      }
    })


    return Object.assign([], this.prospect);
  }

  async saveProspect(prospectData) {

    let token = await this.functions.getUserToken();
  

    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/prospect/${prospectData.id}/.json?auth=${token}`).toPromise();
    if (data["_body"] == 'null') {
      if (prospectData.id) {
        await this.removeCustomer(prospectData, false);
      }  else {
        prospectData.codCliente = await this.configService.getNewLastCustomerCode();
        prospectData.dataDeCadastro = Date.now();
        prospectData.foiProspect = true;

      }
      let resp = await this.http.post(`${this.functions.getBaseURL()}/dados/prospect/.json?auth=${token}`, prospectData).toPromise();
      console.log(resp);
      console.log(JSON.parse(resp['_body']).name)
      let idProspect = JSON.parse(resp['_body']).name
      this.cacheService.invalidateCache(this.cacheService.prospectType);
      this.logServ.addLog({...prospectData, codigo: prospectData.codCliente, id: idProspect}, 'Criou', 'Prospect');
      this.clearList();
      return {codigo: prospectData.codCliente, id: idProspect};
    } else {
      await this.http.put(`${this.functions.getBaseURL()}/dados/prospect/${prospectData.id}/.json?auth=${token}`, prospectData).toPromise();
      this.cacheService.invalidateCache(this.cacheService.prospectType);
      this.logServ.addLog({...prospectData, codigo: prospectData.codCliente}, 'Alterou', 'Prospect');
      this.clearList();
    }

    return {codigo: prospectData.codCliente, id: prospectData.id};
  }

  async removeProspect(prospectData, createLog = true) {
    if (prospectData) {
      let token = await this.functions.getUserToken();
    
      await this.http.delete(`${this.functions.getBaseURL()}/dados/prospect/${prospectData.id}/.json?auth=${token}`).toPromise();
      if(createLog){
      this.logServ.addLog({...prospectData, codigo: prospectData.codCliente}, 'Deletou', 'Prospect');
      }
      this.clearList();

    }
  }

  async getCustomerDataByCode(code) {
    let resp = undefined;
    await this.loadCustomer();
    this.clientes.forEach((item) => {
      if (item.codCliente == code) {
        resp = item;
      }
    });

    return resp;
  }

  async getCustomerDataById(id) {
    let resp = undefined;
    await this.loadCustomer();
    this.clientes.forEach((item) => {
      if (item.uid == id) {
        resp = item;
      }
    });

    return resp;
  }

  async getProspectDataByCode(code) {
    let resp = undefined;
    await this.loadProspect();
    this.prospect.forEach((item) => {
      if (item.codCliente == code) {
        resp = item;
      }
    });

    return resp;
  }

  async getProspectDataById(id) {
    let resp = undefined;
    await this.loadProspect();
    this.prospect.forEach((item) => {
      if (item.uid == id) {
        resp = item;
      }
    });

    return resp;
  }

  async getCustomerByName(name) {
    let customerReturn: Cliente = undefined;
    let customers = await this.loadCustomer();
    let prospect = await this.loadProspect();
    customers = customers.concat(prospect);
    name = name.split(' ').join('');
    customers.forEach((cliente, index) => {
      if (cliente.nomeFantasia) {
        cliente.nomeFantasia = cliente.nomeFantasia + '';
        
        let nomeFant = cliente.nomeFantasia.split(' ').join('');;

        if (nomeFant.toLowerCase() == name.toLowerCase()){
          customerReturn = cliente;
        }
      }

    });
    return customerReturn;
  }

  async getDataByCode(code) {
    let resp = undefined;
    let customers = await this.loadCustomer();
    customers = customers.concat(await this.loadProspect());

    for (let x = 0; x < customers.length; x++){
      if (customers[x].codCliente == code) {
        resp = customers[x];
        break;
      }
    }
    return resp;
  }

  async getByName(name) {
    let customerReturn: Cliente = undefined;
    let customers = await this.loadCustomer();
    customers = customers.concat(await this.loadProspect());
    customers.forEach((cliente, index) => {
      if (cliente.nomeFantasia.toLowerCase() == name.toLowerCase()){
        customerReturn = cliente;
      }
    });
    return customerReturn;
  }

  async  getAddress_Data(cep) {
    let data = await this.http.get(`https://viacep.com.br/ws/${cep}/json/`).toPromise();
    data = JSON.parse(data['_body']);
    if (!data['erro']) {
      return data;
    }
    return undefined;
  }
  

  async saveAnotacoes(anotacoes, codCliente) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/clientes-anotacoes/${codCliente}.json?auth=${token}`, anotacoes).toPromise();
  }

  async loadAnotacoes(codCliente) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/clientes-anotacoes/${codCliente}.json?auth=${token}`).toPromise();    
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async allAprovedClients() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/orcamento/encerrado.json?auth=${token}`).toPromise();    
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async getAllAnotacoes() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/clientes-anotacoes/.json?auth=${token}`).toPromise();
   // let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];
    let list = this.functions.convertObjToArray(data);
    return list;
  }

  //Situção abertura
  async salvarLocal(abert) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/clientesParametros/opcaoLocal/.json?auth=${token}`, abert).toPromise();
  
  }
  
  async getAllLocal() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/clientesParametros/opcaoLocal/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    this.opcaoLocal = data;
     
    return this.opcaoLocal ;
  }

  async salvarSegmento(seg) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/clientesParametros/opcaoSegmento/.json?auth=${token}`, seg).toPromise();
  
  }
  
  async getAllSegmento() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/clientesParametros/opcaoSegmento/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    this.opcaoSegmento = data;
     
    return this.opcaoSegmento;
  }

  async salvarSubSegmento(subSeg) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/clientesParametros/opcaoSubSegmento/.json?auth=${token}`, subSeg).toPromise();
  
  }
  
  async getAllSubSegmento() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/clientesParametros/opcaoSubSegmento/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    this.opcaoSubSegmento = data;
     
    return this.opcaoSubSegmento;
  }


  async salvarStatus(stts) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/clientesParametros/opcaoStatus/.json?auth=${token}`, stts).toPromise();

  }

  async getAllStatus() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/clientesParametros/opcaoStatus/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    this.opcaoStatus = data;

    return this.opcaoStatus;
  }
  // CONTATOS
  async saveContatos(contatos, codCliente) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/clientes-contatos/${codCliente}.json?auth=${token}`, contatos).toPromise();
  }

  async loadContatos(codCliente) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/clientes-contatos/${codCliente}.json?auth=${token}`).toPromise();
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async loadAllContatos() {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/clientes-contatos.json?auth=${token}`).toPromise();
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return Object.keys(list).map((key) => [key, list[key]]);
  }

  async getAllContatos() {
    let token = await this.functions.getUserToken();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/dados/clientes-contatos/.json?auth=${token}`).toPromise();
    let data = this.functions.convertObjToArray(resp);
    let list = data;

    return list;
  }
  // PROJETOS

  async saveProjetos(projetos, codCliente) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/dados/clientes-projetos/${codCliente}.json?auth=${token}`, projetos).toPromise();
  }

  async loadProjetos(codCliente) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/dados/clientes-projetos/${codCliente}.json?auth=${token}`).toPromise();
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

}
