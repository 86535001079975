import { Injectable, EventEmitter } from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class HttpLoaderService {

    public loader = new EventEmitter();

    public isLoader() {
        return this.loader;
    }

    public setLoader(isLoader) {
        this.loader.emit(isLoader);
    }

}
