import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { TranslateLoader } from '@ngx-translate/core';
import { AngularFireAuth } from '@angular/fire/auth';

import * as env from "../../../../environments/environment";
import { map } from "rxjs/operators";

import { UserService } from '../../../shared/services/user/user-service.service';
import { FunctionsService } from '../utilsFunctions/functions.service';

@Injectable({
  providedIn: 'root'
})
export class TranslationService implements TranslateLoader {

  constructor(private http: HttpClient,
              private afAuth: AngularFireAuth,
              private functions: FunctionsService,
              private userService: UserService) {}

  getTranslation(lang: string): Observable<any> {  
    let base = JSON.parse(localStorage.getItem("system-base"));
    //try {
      return new Observable(observer => {
        return this.http.get(`https://r9u226ztm7.execute-api.us-east-1.amazonaws.com/default/OSExterno-loadTerms?base=${base.base}`).subscribe((data) => {
            observer.next(data);
            observer.complete();  
      }, (error: Error) => {
        observer.next({});
      })});
      /*
      return this.http.get(`https://r9u226ztm7.execute-api.us-east-1.amazonaws.com/default/OSExterno-loadTerms?base=${base.base}`).pipe(
        map((response: JSON) => {
          return response;
        }));      
        */
    /*} catch (error) {
      let retorno = Observable.create(() => {
        return {}
      })
      return retorno;
    }*/
  }  

  async saveTerms(terms) {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase(); 
    await this.http.put(`${this.functions.getBaseURL()}/dados/config/terms/.json?auth=${token}`, terms).toPromise();
  }

  async loadTerms() {
    let token = await this.functions.getUserToken();
    let base = this.userService.getDataBase(); 
    let translation = await this.http.get(`${this.functions.getBaseURL()}/dados/config/terms/.json?auth=${token}`).toPromise();
    return translation;
  }
}
