import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import * as moment from 'moment';

import * as env from "../../../../environments/environment";
import { FunctionsService } from '../utilsFunctions/functions.service';
import { UserService } from '../../../shared/services/user/user-service.service';
import { OutgoingService } from "./outgoing.service";
import { IncomeService } from "./income.service";

@Injectable({
  providedIn: 'root'
})
export class CashflowService {
  todosBancos = [];
  baseBancos = [];
  tiposDespesaReceita = [];
  public paymentType = [{ 'value': '-1', 'label': 'Formas de pagamento' },
  { 'value': 'cc', 'label': 'Cartão Crédito' },
  { 'value': 'cd', 'label': 'Cartão Débito' },
  { 'value': 'ch', 'label': 'Cheque' },
  { 'value': 'di', 'label': 'Dinheiro' },
  { 'value': 'bo', 'label': 'Boleto' },
  { 'value': 'ted', 'label': 'Transferência' },
  { 'value': 'pix', 'label': 'PIX' }];

  constructor(private users: UserService,
    private outgoing: OutgoingService,
    private income: IncomeService,
    private functionsService: FunctionsService,
    private http: Http) {
  }

  getPaymentDesc(code) {
    let data = this.paymentType.filter(item => item.value == code);
    return data[0].label;
  }

  async saveTypes(dataTypes) {
    //PRECISA SALVAR COM KEY para poder referenciar dentro do fluxo e otimizar o filtro
    let token = await this.functionsService.getUserToken();

    await this.http.put(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/tipos/.json?auth=${token}`, dataTypes).toPromise();
    this.tiposDespesaReceita = [];
  }

  async loadTypes() {
    if (this.tiposDespesaReceita.length == 0) {
      let token = await this.functionsService.getUserToken();
      let data = await this.http.get(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/tipos/.json?auth=${token}`).toPromise();
      this.tiposDespesaReceita = this.functionsService.convertObjToArray(data).filter((item) => item);

      this.tiposDespesaReceita.sort((a, b) => {
        if (a.desc < b.desc) {
          return -1
        } else {
          if (a.desc > b.desc) {
            return 1
          } else {
            return 0
          }
        }
      });
    }

    return this.tiposDespesaReceita;
  }

  async saveFeePaymentMethod(dados) {
    let token = await this.functionsService.getUserToken();

    await this.http.put(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/taxa_tipo_pagamento/.json?auth=${token}`, dados).toPromise();
  }

  async loadFeePaymentMethod() {
    let token = await this.functionsService.getUserToken();
    let data = await this.http.get(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/taxa_tipo_pagamento/.json?auth=${token}`).toPromise();
    let array = this.functionsService.convertObjToArray(data).filter((item) => item);

    if (array.length == 0) {
      array = Object.assign([], this.paymentType);
      array.splice(0, 1);
      array.map((item) => {
        item['perc'] = 0;
      });
    }
    return array;
  }

  async hasFeePaymentMethod() {
    let token = await this.functionsService.getUserToken();
    let data = await this.http.get(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/taxa_tipo_pagamento/.json?auth=${token}`).toPromise();
    let array = this.functionsService.convertObjToArray(data).filter((item) => item);

    return array.length > 0;
  }

  async saveBaseBanks(dataBanks) {
    //PRECISA SALVAR COM KEY para poder referenciar dentro do fluxo e otimizar o filtro
    let token = await this.functionsService.getUserToken();

    await this.http.put(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/bancos/.json?auth=${token}`, dataBanks).toPromise();
    this.baseBancos = [];
  }

  async loadBaseBanks() {
    if (this.baseBancos.length == 0) {
      let token = await this.functionsService.getUserToken();

      let data = await this.http.get(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/bancos/.json?auth=${token}`).toPromise();
      this.baseBancos = this.functionsService.convertObjToArray(data).filter((item) => item);
    }

    return this.baseBancos;
  }

  async loadAllBanks() {
    if (this.todosBancos.length == 0) {
      let token = await this.functionsService.getUserToken();
      let data = await this.http.get(`${this.functionsService.getBaseURL()}/bancos/.json?auth=${token}`).toPromise();

      this.todosBancos = this.functionsService.convertObjToArray(data).filter((item) => item);
      this.todosBancos = [{ value: 0, label: "Selecione um banco" }, ...this.todosBancos];
    }

    return this.todosBancos;
  }
  async getBankName(codBank) {
    let token = await this.functionsService.getUserToken();
    let data = await this.http.get(`${this.functionsService.getBaseURL()}/bancos/${codBank}.json?auth=${token}`).toPromise();

    return JSON.parse(data['_body']);
  }

  async delete(type, month, year, data, confirmation = true) {
    if (type == 0) {
      return await this.outgoing.deleteOutgoing(month, year, data, confirmation);
    } else {
      return await this.income.deleteIncome(month, year, data, confirmation);
    }
  }

  async save(type, month, year, data) {
    if (type == 0) {
      return await this.outgoing.saveOutgoing(month, year, data);
    } else {
      return await this.income.saveIncome(month, year, data);
    }
  }

  async saveInstallment(type, month, year, data) {
    let count = +data.repeatInstallmentNumber;
    data.repeatInstallmentTotal = data.repeatTime;
    let id = data['id'];
    for (count; count < data.repeatTime; count++) {
      data.repeatInstallmentNumber = count + 1;
      await this.save(type, month, year, data);
      if (month < 12) {
        month++;
      } else {
        month = 1;
        year++;
      }
      data.dueDate.setMonth(month - 1);
      data.paidDate.setMonth(month - 1);
      data.dueDate.setYear(year)
      data.paidDate.setYear(year)
    }
  }

  async saveIndefiniteInstallment(type, data) {
    if (type == 0) {
      return await this.outgoing.saveIndefiniteOutgoing(data);
    } else {
      return await this.income.saveIndefiniteIncome(data);
    }
  }

  async saveMonthBalance(month, year, data) {
    let token = await this.functionsService.getUserToken();
    await this.http.put(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/saldos/${year}/${month}/.json?auth=${token}`, data).toPromise();
  }

  async getAllBalance() {
    let token = await this.functionsService.getUserToken();
    let data = await this.http.get(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/saldos/.json?auth=${token}`).toPromise();

    data = JSON.parse(data['_body']);

    let returnData = [];
    if (data) {
      Object.keys(data).map((year) => {
        Object.keys(data[year]).map((month) => {
          returnData.push(data[year][month]);
        });
      });
      returnData = returnData.filter((item) => item != null);
      returnData.sort((a, b) => {
        let dateA = moment(a.date);
        let dateB = moment(b.date);
        if (dateA.isBefore(dateB)) {
          return 1
        } else {
          if (dateB.isBefore(dateA)) {
            return -1
          }
        }
        return 0
      });
    }

    return returnData;
  }

  async removeMonthBalance(month, year) {
    let token = await this.functionsService.getUserToken();
    await this.http.delete(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/saldos/${year}/${month}/.json?auth=${token}`).toPromise();
  }

  async getMonth(type, month, year) {
    if (type == 0) {
      return await this.outgoing.getMonthOutgoing(month, year);
    } else {
      return await this.income.getMonthIncome(month, year);
    }
  }

  async getById(type, month, year, id) {
    if (type == 0) {
      return await this.outgoing.getMonthOutgoingById(month, year, id);
    } else {
      return await this.income.getMonthIncomeById(month, year, id);
    }
  }

  /*
  async saveAllBanks() {
    let token = await this.functionsService.getUserToken();

    this.bancos.forEach((item) => {
      item.label = item.label.toString().trimLeft().trimRight();
      item.value = item.value.toString().trimLeft().trimRight();
      this.http.put(`${this.functionsService.getBaseURL()}/bancos/${item.value}.json?auth=${token}`, item).toPromise();  
    });
  }


  bancos = [
    {value: "	246	", label: "	Banco ABC Brasil S.A.	"},
    {value: "	748	", label: "	Banco Cooperativo Sicredi S.A.	"},
    {value: "	117	", label: "	Advanced Cc Ltda	"},
    {value: "	121	", label: "	Banco Agibank S.A.	"},
    {value: "	172	", label: "	Albatross Ccv S.A	"},
    {value: "	188	", label: "	Ativa Investimentos S.A	"},
    {value: "	280	", label: "	Avista S.A. Crédito, Financiamento e Investimento	"},
    {value: "	80	", label: "	B&T Cc Ltda	"},
    {value: "	654	", label: "	Banco A.J.Renner S.A.	"},
    {value: "	246	", label: "	Banco ABC Brasil S.A.	"},
    {value: "	75	", label: "	Banco ABN AMRO S.A	"},
    {value: "	121	", label: "	Banco Agibank S.A.	"},
    {value: "	25	", label: "	Banco Alfa S.A.	"},
    {value: "	641	", label: "	Banco Alvorada S.A.	"},
    {value: "	65	", label: "	Banco Andbank (Brasil) S.A.	"},
    {value: "	213	", label: "	Banco Arbi S.A.	"},
    {value: "	96	", label: "	Banco B3 S.A.	"},
    {value: "	24	", label: "	Banco BANDEPE S.A.	"},
    {value: "	318	", label: "	Banco BMG S.A.	"},
    {value: "	752	", label: "	Banco BNP Paribas Brasil S.A.	"},
    {value: "	107	", label: "	Banco BOCOM BBM S.A.	"},
    {value: "	63	", label: "	Banco Bradescard S.A.	"},
    {value: "	36	", label: "	Banco Bradesco BBI S.A.	"},
    {value: "	122	", label: "	Banco Bradesco BERJ S.A.	"},
    {value: "	204	", label: "	Banco Bradesco Cartões S.A.	"},
    {value: "	394	", label: "	Banco Bradesco Financiamentos S.A.	"},
    {value: "	237	", label: "	Banco Bradesco S.A.	"},
    {value: "	218	", label: "	Banco BS2 S.A.	"},
    {value: "	208	", label: "	Banco BTG Pactual S.A.	"},
    {value: "	336	", label: "	Banco C6 S.A – C6 Bank	"},
    {value: "	473	", label: "	Banco Caixa Geral – Brasil S.A.	"},
    {value: "	412	", label: "	Banco Capital S.A.	"},
    {value: "	40	", label: "	Banco Cargill S.A.	"},
    {value: "	368	", label: "	Banco Carrefour	"},
    {value: "	266	", label: "	Banco Cédula S.A.	"},
    {value: "	739	", label: "	Banco Cetelem S.A.	"},
    {value: "	233	", label: "	Banco Cifra S.A.	"},
    {value: "	745	", label: "	Banco Citibank S.A.	"},
    {value: "	241	", label: "	Banco Clássico S.A.	"},
    {value: "	756	", label: "	Banco Cooperativo do Brasil S.A. – BANCOOB	"},
    {value: "	748	", label: "	Banco Cooperativo Sicredi S.A.	"},
    {value: "	222	", label: "	Banco Credit Agricole Brasil S.A.	"},
    {value: "	505	", label: "	Banco Credit Suisse (Brasil) S.A.	"},
    {value: "	69	", label: "	Banco Crefisa S.A.	"},
    {value: "	3	", label: "	Banco da Amazônia S.A.	"},
    {value: "	83	", label: "	Banco da China Brasil S.A.	"},
    {value: "	707	", label: "	Banco Daycoval S.A.	"},
    {value: "	51	", label: "	Banco de Desenvolvimento do Espírito Santo S.A.	"},
    {value: "	300	", label: "	Banco de La Nacion Argentina	"},
    {value: "	495	", label: "	Banco de La Provincia de Buenos Aires	"},
    {value: "	494	", label: "	Banco de La Republica Oriental del Uruguay	"},
    {value: "	335	", label: "	Banco Digio S.A	"},
    {value: "	1	", label: "	Banco do Brasil S.A.	"},
    {value: "	47	", label: "	Banco do Estado de Sergipe S.A.	"},
    {value: "	37	", label: "	Banco do Estado do Pará S.A.	"},
    {value: "	41	", label: "	Banco do Estado do Rio Grande do Sul S.A.	"},
    {value: "	4	", label: "	Banco do Nordeste do Brasil S.A.	"},
    {value: "	196	", label: "	Banco Fair Corretora de Câmbio S.A	"},
    {value: "	265	", label: "	Banco Fator S.A.	"},
    {value: "	224	", label: "	Banco Fibra S.A.	"},
    {value: "	626	", label: "	Banco Ficsa S.A.	"},
    {value: "	94	", label: "	Banco Finaxis S.A.	"},
    {value: "	612	", label: "	Banco Guanabara S.A.	"},
    {value: "	12	", label: "	Banco Inbursa S.A.	"},
    {value: "	604	", label: "	Banco Industrial do Brasil S.A.	"},
    {value: "	653	", label: "	Banco Indusval S.A.	"},
    {value: "	77	", label: "	Banco Inter S.A.	"},
    {value: "	249	", label: "	Banco Investcred Unibanco S.A.	"},
    {value: "	184	", label: "	Banco Itaú BBA S.A.	"},
    {value: "	29	", label: "	Banco Itaú Consignado S.A.	"},
    {value: "	479	", label: "	Banco ItauBank S.A	"},
    {value: "	376	", label: "	Banco J. P. Morgan S.A.	"},
    {value: "	74	", label: "	Banco J. Safra S.A.	"},
    {value: "	217	", label: "	Banco John Deere S.A.	"},
    {value: "	76	", label: "	Banco KDB S.A.	"},
    {value: "	757	", label: "	Banco KEB HANA do Brasil S.A.	"},
    {value: "	600	", label: "	Banco Luso Brasileiro S.A.	"},
    {value: "	243	", label: "	Banco Máxima S.A.	"},
    {value: "	720	", label: "	Banco Maxinvest S.A.	"},
    {value: "	389	", label: "	Banco Mercantil de Investimentos S.A.	"},
    {value: "	389	", label: "	Banco Mercantil do Brasil S.A.	"},
    {value: "	370	", label: "	Banco Mizuho do Brasil S.A.	"},
    {value: "	746	", label: "	Banco Modal S.A.	"},
    {value: "	66	", label: "	Banco Morgan Stanley S.A.	"},
    {value: "	456	", label: "	Banco MUFG Brasil S.A.	"},
    {value: "	7	", label: "	Banco Nacional de Desenvolvimento Econômico e Social – BNDES	"},
    {value: "	169	", label: "	Banco Olé Bonsucesso Consignado S.A.	"},
    {value: "	111	", label: "	Banco Oliveira Trust Dtvm S.A	"},
    {value: "	79	", label: "	Banco Original do Agronegócio S.A.	"},
    {value: "	212	", label: "	Banco Original S.A.	"},
    {value: "	712	", label: "	Banco Ourinvest S.A.	"},
    {value: "	623	", label: "	Banco PAN S.A.	"},
    {value: "	611	", label: "	Banco Paulista S.A.	"},
    {value: "	643	", label: "	Banco Pine S.A.	"},
    {value: "	658	", label: "	Banco Porto Real de Investimentos S.A.	"},
    {value: "	747	", label: "	Banco Rabobank International Brasil S.A.	"},
    {value: "	633	", label: "	Banco Rendimento S.A.	"},
    {value: "	741	", label: "	Banco Ribeirão Preto S.A.	"},
    {value: "	120	", label: "	Banco Rodobens S.A.	"},
    {value: "	422	", label: "	Banco Safra S.A.	"},
    {value: "	33	", label: "	Banco Santander (Brasil) S.A.	"},
    {value: "	743	", label: "	Banco Semear S.A.	"},
    {value: "	754	", label: "	Banco Sistema S.A.	"},
    {value: "	630	", label: "	Banco Smartbank S.A.	"},
    {value: "	366	", label: "	Banco Société Générale Brasil S.A.	"},
    {value: "	637	", label: "	Banco Sofisa S.A.	"},
    {value: "	464	", label: "	Banco Sumitomo Mitsui Brasileiro S.A.	"},
    {value: "	82	", label: "	Banco Topázio S.A.	"},
    {value: "	634	", label: "	Banco Triângulo S.A.	"},
    {value: "	18	", label: "	Banco Tricury S.A.	"},
    {value: "	655	", label: "	Banco Votorantim S.A.	"},
    {value: "	610	", label: "	Banco VR S.A.	"},
    {value: "	119	", label: "	Banco Western Union do Brasil S.A.	"},
    {value: "	124	", label: "	Banco Woori Bank do Brasil S.A.	"},
    {value: "	348	", label: "	Banco Xp S/A	"},
    {value: "	81	", label: "	BancoSeguro S.A.	"},
    {value: "	21	", label: "	BANESTES S.A. Banco do Estado do Espírito Santo	"},
    {value: "	755	", label: "	Bank of America Merrill Lynch Banco Múltiplo S.A.	"},
    {value: "	268	", label: "	Barigui Companhia Hipotecária	"},
    {value: "	250	", label: "	BCV – Banco de Crédito e Varejo S.A.	"},
    {value: "	144	", label: "	BEXS Banco de Câmbio S.A.	"},
    {value: "	253	", label: "	Bexs Corretora de Câmbio S/A	"},
    {value: "	134	", label: "	Bgc Liquidez Dtvm Ltda	"},
    {value: "	17	", label: "	BNY Mellon Banco S.A.	"},
    {value: "	301	", label: "	Bpp Instituição De Pagamentos S.A	"},
    {value: "	126	", label: "	BR Partners Banco de Investimento S.A.	"},
    {value: "	70	", label: "	BRB – Banco de Brasília S.A.	"},
    {value: "	92	", label: "	Brickell S.A. Crédito, Financiamento e Investimento	"},
    {value: "	173	", label: "	BRL Trust Distribuidora de Títulos e Valores Mobiliários S.A.	"},
    {value: "	142	", label: "	Broker Brasil Cc Ltda	"},
    {value: "	292	", label: "	BS2 Distribuidora de Títulos e Valores Mobiliários S.A.	"},
    {value: "	11	", label: "	C.Suisse Hedging-Griffo Cv S.A (Credit Suisse)	"},
    {value: "	104	", label: "	Caixa Econômica Federal	"},
    {value: "	288	", label: "	Carol Distribuidora de Títulos e Valor Mobiliários Ltda	"},
    {value: "	130	", label: "	Caruana Scfi	"},
    {value: "	159	", label: "	Casa Credito S.A	"},
    {value: "	16	", label: "	Ccm Desp Trâns Sc E Rs	"},
    {value: "	89	", label: "	Ccr Reg Mogiana	"},
    {value: "	114	", label: "	Central Cooperativa De Crédito No Estado Do Espírito Santo	"},
    {value: "	114-7	", label: "	Central das Cooperativas de Economia e Crédito Mútuo doEstado do Espírito Santo Ltda.	"},
    {value: "	320	", label: "	China Construction Bank (Brasil) Banco Múltiplo S.A.	"},
    {value: "	477	", label: "	Citibank N.A.	"},
    {value: "	180	", label: "	Cm Capital Markets Cctvm Ltda	"},
    {value: "	127	", label: "	Codepe Cvc S.A	"},
    {value: "	163	", label: "	Commerzbank Brasil S.A. – Banco Múltiplo	"},
    {value: "	60	", label: "	Confidence Cc S.A	"},
    {value: "	85	", label: "	Coop Central Ailos	"},
    {value: "	97	", label: "	Cooperativa Central de Crédito Noroeste Brasileiro Ltda.	"},
    {value: "	085-x	", label: "	Cooperativa Central de Crédito Urbano-CECRED	"},
    {value: "	090-2	", label: "	Cooperativa Central de Economia e Crédito Mutuo – SICOOB UNIMAIS	"},
    {value: "	087-6	", label: "	Cooperativa Central de Economia e Crédito Mútuo das Unicredsde Santa Catarina e Paraná	"},
    {value: "	089-2	", label: "	Cooperativa de Crédito Rural da Região da Mogiana	"},
    {value: "	286	", label: "	Cooperativa de Crédito Rural De Ouro	"},
    {value: "	279	", label: "	Cooperativa de Crédito Rural de Primavera Do Leste	"},
    {value: "	273	", label: "	Cooperativa de Crédito Rural de São Miguel do Oeste – Sulcredi/São Miguel	"},
    {value: "	98	", label: "	Credialiança Ccr	"},
    {value: "	098-1	", label: "	CREDIALIANÇA COOPERATIVA DE CRÉDITO RURAL	"},
    {value: "	10	", label: "	Credicoamo	"},
    {value: "	133	", label: "	Cresol Confederação	"},
    {value: "	182	", label: "	Dacasa Financeira S/A	"},
    {value: "	707	", label: "	Banco Daycoval S.A.	"},
    {value: "	487	", label: "	Deutsche Bank S.A. – Banco Alemão	"},
    {value: "	140	", label: "	Easynvest – Título Cv S.A	"},
    {value: "	149	", label: "	Facta S.A. Cfi	"},
    {value: "	285	", label: "	Frente Corretora de Câmbio Ltda.	"},
    {value: "	278	", label: "	Genial Investimentos Corretora de Valores Mobiliários S.A.	"},
    {value: "	138	", label: "	Get Money Cc Ltda	"},
    {value: "	64	", label: "	Goldman Sachs do Brasil Banco Múltiplo S.A.	"},
    {value: "	177	", label: "	Guide Investimentos S.A. Corretora de Valores	"},
    {value: "	146	", label: "	Guitta Corretora de Câmbio Ltda	"},
    {value: "	78	", label: "	Haitong Banco de Investimento do Brasil S.A.	"},
    {value: "	62	", label: "	Hipercard Banco Múltiplo S.A.	"},
    {value: "	189	", label: "	HS Financeira S/A Crédito, Financiamento e Investimentos	"},
    {value: "	269	", label: "	HSBC Brasil S.A. – Banco de Investimento	"},
    {value: "	271	", label: "	IB Corretora de Câmbio, Títulos e Valores Mobiliários S.A.	"},
    {value: "	157	", label: "	Icap Do Brasil Ctvm Ltda	"},
    {value: "	132	", label: "	ICBC do Brasil Banco Múltiplo S.A.	"},
    {value: "	492	", label: "	ING Bank N.V.	"},
    {value: "	139	", label: "	Intesa Sanpaolo Brasil S.A. – Banco Múltiplo	"},
    {value: "	652	", label: "	Itaú Unibanco Holding S.A.	"},
    {value: "	341	", label: "	Itaú Unibanco S.A.	"},
    {value: "	488	", label: "	JPMorgan Chase Bank, National Association	"},
    {value: "	399	", label: "	Kirton Bank S.A. – Banco Múltiplo	"},
    {value: "	293	", label: "	Lastro RDV Distribuidora de Títulos e Valores Mobiliários Ltda.	"},
    {value: "	105	", label: "	Lecca Crédito, Financiamento e Investimento S/A	"},
    {value: "	145	", label: "	Levycam Ccv Ltda	"},
    {value: "	113	", label: "	Magliano S.A	"},
    {value: "	323	", label: "	Mercado Pago – Conta Do Mercado Livre	"},
    {value: "	128	", label: "	MS Bank S.A. Banco de Câmbio	"},
    {value: "	137	", label: "	Multimoney Cc Ltda	"},
    {value: "	14	", label: "	Natixis Brasil S.A. Banco Múltiplo	"},
    {value: "	191	", label: "	Nova Futura Corretora de Títulos e Valores Mobiliários Ltda.	"},
    {value: "	753	", label: "	Novo Banco Continental S.A. – Banco Múltiplo	"},
    {value: "	260	", label: "	Nu Pagamentos S.A (Nubank)	"},
    {value: "	613	", label: "	Omni Banco S.A.	"},
    {value: "	613	", label: "	Omni Banco S.A.	"},
    {value: "	290	", label: "	Pagseguro Internet S.A	"},
    {value: "	254	", label: "	Paraná Banco S.A.	"},
    {value: "	326	", label: "	Parati – Crédito Financiamento e Investimento S.A.	"},
    {value: "	194	", label: "	Parmetal Distribuidora de Títulos e Valores Mobiliários Ltda	"},
    {value: "	174	", label: "	Pernambucanas Financ S.A	"},
    {value: "	100	", label: "	Planner Corretora De Valores S.A	"},
    {value: "	125	", label: "	Plural S.A. – Banco Múltiplo	"},
    {value: "	93	", label: "	Pólocred Scmepp Ltda	"},
    {value: "	108	", label: "	Portocred S.A	"},
    {value: "	283	", label: "	Rb Capital Investimentos Dtvm Ltda	"},
    {value: "	101	", label: "	Renascenca Dtvm Ltda	"},
    {value: "	270	", label: "	Sagitur Corretora de Câmbio Ltda.	"},
    {value: "	751	", label: "	Scotiabank Brasil S.A. Banco Múltiplo	"},
    {value: "	276	", label: "	Senff S.A. – Crédito, Financiamento e Investimento	"},
    {value: "	545	", label: "	Senso Ccvm S.A	"},
    {value: "	190	", label: "	Servicoop	"},
    {value: "	183	", label: "	Socred S.A	"},
    {value: "	299	", label: "	Sorocred Crédito, Financiamento e Investimento S.A.	"},
    {value: "	118	", label: "	Standard Chartered Bank (Brasil) S/A–Bco Invest.	"},
    {value: "	197	", label: "	Stone Pagamentos S.A	"},
    {value: "	340	", label: "	Super Pagamentos e Administração de Meios Eletrônicos S.A.	"},
    {value: "	95	", label: "	Travelex Banco de Câmbio S.A.	"},
    {value: "	143	", label: "	Treviso Corretora de Câmbio S.A.	"},
    {value: "	131	", label: "	Tullett Prebon Brasil Cvc Ltda	"},
    {value: "	129	", label: "	UBS Brasil Banco de Investimento S.A.	"},
    {value: "	091-4	", label: "	Unicred Central do Rio Grande do Sul	"},
    {value: "	91	", label: "	Unicred Central Rs	"},
    {value: "	136	", label: "	Unicred Cooperativa	"},
    {value: "	99	", label: "	UNIPRIME Central – Central Interestadual de Cooperativas de Crédito Ltda.	"},
    {value: "	84	", label: "	Uniprime Norte do Paraná – Coop de Economia eCrédito Mútuo dos Médicos, Profissionais das Ciências	"},
    {value: "	298	", label: "	Vips Cc Ltda	"},
    {value: "	310	", label: "	Vortx Distribuidora de Títulos e Valores Mobiliários Ltda	"},
    {value: "	102	", label: "	Xp Investimentos S.A	"},
  ];
  */


}