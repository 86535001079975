import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import * as moment from 'moment';

import * as env from "../../../../environments/environment";
import { FunctionsService } from '../utilsFunctions/functions.service';
import { UserService } from '../../../shared/services/user/user-service.service';
import { ConfigService } from '../config/config.service';
import { ConfirmationDialogService } from '../../../layout/confirmation-dialog/confirmation-dialog.service';
import { LogService } from '../logs/log.service';

@Injectable({
  providedIn: 'root'
})
export class OutgoingService {

  constructor(private dialog: ConfirmationDialogService,
              private functionsService: FunctionsService,
              private users: UserService,
              private http: Http,
              private logServ : LogService,
              private config: ConfigService) { }


  async saveOutgoing(month, year, cashflowData) {
    let token = await this.functionsService.getUserToken();
    let base = this.users.getDataBase();
    if (cashflowData.id) {
      await this.http.put(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/${year}/${month}/${cashflowData.id}/.json?auth=${token}`, cashflowData).toPromise();
      this.logServ.addLog(cashflowData, 'Alterou', 'Despesas');
      return cashflowData.id;
    } else {
      let data = await this.http.post(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/${year}/${month}/.json?auth=${token}`, cashflowData).toPromise();
      cashflowData.id = JSON.parse(data['_body']).name;
      this.logServ.addLog(cashflowData, 'Criou', 'Despesas');
      return JSON.parse(data['_body']).name;
    }
  }

  async deleteOutgoing(month, year, obj, confirmation) {
    let token = await this.functionsService.getUserToken();
    let base = this.users.getDataBase();
    await this.http.delete(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/${year}/${month}/${obj['id']}/.json?auth=${token}`).toPromise();

    if (obj.repeat) {
      if (confirmation && await this.dialog.confirm('Confirmação', 'Este é um lançamento de repetição, deseja excluir os meses subsequentes também?', 'Sim', 'Não')) {
        if (obj.repeatType == 'monthly') {
          // Excluí o atual em cima e começa no próximo
          if (month < 12) {
            month++;
          } else {
            month = 1;
            year++;
          }
          this.clearId(obj, month, year);
        } else {
          if (obj.repeatType == 'indefinite') {
            // Como não está pago, está salvo como ALL ainda, excluir registro global
            await this.deleteIndefiniteOutgoing(obj['id']);
          }
        }
      } else {
        if (obj.repeatType == 'indefinite') {
          // Adiconar como ecxceção do mês
          await this.http.put(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/except/${year}/${month}/${obj['id']}/.json?auth=${token}`, obj).toPromise();
          this.logServ.addLog(obj, 'Alterou', 'Despesas');
        }
      }
    }
  }

  async clearId(obj, month, year) {
    let token = await this.functionsService.getUserToken();
    let base = this.users.getDataBase();
    let id = obj['id'];
    let AuxMonth = month;
    let AuxYear = year;
    
    for (let count = obj.repeatInstallmentNumber; count <= obj.repeatInstallmentTotal; count++) {
      await this.http.delete(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/${AuxYear}/${AuxMonth}/${id}/.json?auth=${token}`).toPromise();
      this.logServ.addLog(obj, 'Deletou', 'Despesas');
      if (AuxMonth < 12) {
        AuxMonth++;
      } else {
        AuxMonth = 1;
        AuxYear++;
      }
    }    
  }

  async getMonthOutgoing(month, year) {
    //console.log(new Date);
    let token = await this.functionsService.getUserToken();
    let base = this.users.getDataBase();
    let data = await this.http.get(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/${year}/${month}/.json?auth=${token}`).toPromise();  
    //console.log(new Date);

    let allData = this.functionsService.convertObjToArray(data).filter((item) => item);
    let arrayIndefinite = await this.getIndefiniteOutgoingList();
    let exceptList = await this.getMonthOutgoingExcept(month, year);
    //console.log(new Date);
    arrayIndefinite.forEach((item) => {
      let momentCreationDate = moment(item.creationDate);
      momentCreationDate.date(1);
      let selectedDate = moment(month, 'MM');
      selectedDate.date(2);
      selectedDate.year(year);

      if (selectedDate.isAfter(momentCreationDate)) {
        let isExcept = false;
        exceptList.forEach((data) => {
          if (item.id == data.id) {
            isExcept = true;
          }
        });
        if (isExcept) {
          return
        }

        let found = false;
        allData.forEach((data) => {
          if (data.id == item.id) {
            found = true;
          }
        });


        if (!found) {
          let configDate = new Date(item.dueDate);
          configDate.setMonth(month-1);
          configDate.setFullYear(year);
          item.dueDate = configDate;
          
          allData.push(item);
        }
      }
    });
    //console.log(new Date);
    //allData = [...allData, ...array];

    return allData;
  }
  async getMonthOutgoingExcept(month, year) {
    let token = await this.functionsService.getUserToken();
    let base = this.users.getDataBase();
    let data = await this.http.get(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/except/${year}/${month}/.json?auth=${token}`).toPromise();   
    return this.functionsService.convertObjToArray(data).filter((item) => item);

  }
  async getMonthOutgoingById(month, year, id) {
    let token = await this.functionsService.getUserToken();
    let base = this.users.getDataBase();
    let data = await this.http.get(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/${year}/${month}/${id}.json?auth=${token}`).toPromise();   
    return JSON.parse(data['_body']);
  }

  async saveIndefiniteOutgoing(cashflowData) {
    let token = await this.functionsService.getUserToken();
    let base = this.users.getDataBase();
    if (cashflowData.id) {
      await this.http.put(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/all/${cashflowData.id}/.json?auth=${token}`, cashflowData).toPromise();
      this.logServ.addLog(cashflowData, 'Alterou', 'Despesas');
      return cashflowData.id;
    } else {
      cashflowData['creationDate'] = new Date(cashflowData.dueDate);
      let data = await this.http.post(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/all/.json?auth=${token}`, cashflowData).toPromise();
      cashflowData.id = JSON.parse(data['_body']).name;
      this.logServ.addLog(cashflowData, 'Criou', 'Despesas');
      return JSON.parse(data['_body']).name;
    }
  }
  async deleteIndefiniteOutgoing(id) {
    let token = await this.functionsService.getUserToken();
    let base = this.users.getDataBase();
    await this.http.delete(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/all/${id}/.json?auth=${token}`).toPromise();
    this.logServ.addLog(id, 'Deletou', 'Despesas');

  }
  async getIndefiniteOutgoingList() {
    let token = await this.functionsService.getUserToken();
    let base = this.users.getDataBase();
    let data = await this.http.get(`${this.functionsService.getBaseURL()}/dados/fluxo_caixa/despesas/all/.json?auth=${token}`).toPromise();   
    return this.functionsService.convertObjToArray(data).filter((item) => item);
  }
}
