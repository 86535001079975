import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { first } from 'rxjs/operators';
import * as env from "../../../../environments/environment";
import { FunctionsService } from "../../../shared/services/utilsFunctions/functions.service";

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient,
              private router: Router,
              public functionsService: FunctionsService,
              private afAuth: AngularFireAuth) { }


  getDataBase() {
    let base = JSON.parse(localStorage.getItem("system-base"));
    if (!base) {
      this.router.navigate(['/login']);
      return 
    }

    return base.base;
  }

  async getUserToken() {
    let token = await this.afAuth.idToken.pipe(first()).toPromise();
    return token;
  }

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  async get(path) {
    let token = await this.getUserToken();
    let baseURL = `${this.functionsService.getBaseURL()}`;
    return await this.http.get(baseURL + path + `.json?auth=${token}`).toPromise();
  }
}
