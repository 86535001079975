import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';

import * as env from "../../../../environments/environment";
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { Http, Headers, RequestOptionsArgs } from '@angular/http';
import { first } from 'rxjs/operators';

import { User } from '../../../classes/user';
import { OsService } from '../os/os.service';
import { FunctionsService } from '../utilsFunctions/functions.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  userList: any;
  fireUser: any = undefined;
  userData: any = undefined;
  usuarios: any[] = [];
  private tecUsers: any[] = [];

  private random = 0;

  constructor(
        private messageService: MessageService,
        private afAuth: AngularFireAuth,
        private db: AngularFireDatabase,
        private router: Router,
        private http: Http,
        private osService: OsService,
        private functions: FunctionsService) {
  }

  currentUser() {
    //return this.afAuth.authState;
  }

  message(msg, type = 'warn', summary = 'Atenção', time = 5000) {
    this.messageService.add({severity:type, summary: summary, detail:msg, life: time});
  }

  async newUser(usuario) {
    usuario.base = this.getDataBase();
    if (usuario.username.indexOf('@') > -1) {
      this.message('Username não pode possuir @.');
      return false;
    }
    if (usuario.username.indexOf(' ') > -1) {
      this.message('Username não pode possuir espaço.');
      return false;
    }

    if (!usuario.email) {
      this.message('E-mail deve ser informado.');
      return false;
    }

    if (!usuario.username) {
      this.message('Username deve ser informado.');
      return false;
    }

    if (!usuario.nome) {
      this.message('Nome deve ser informado.');
      return false;
    }

    let database = this.getDataBase();
    let resp = await this.http.get(`${this.functions.getBaseURL()}/usuarios/.json`).toPromise();
    let allUsers = this.convertObjToArray(resp);
    allUsers = allUsers.filter((item) => item.email == usuario.email);
    if (allUsers.length == 0) { // Criar novo usuário no firebase, apenase se ainda não exister.
      if (!confirm(`Os dados de acesso serão enviado ao e-mail informado, deseja continuar?`)) {
        return
      }
      let token = await this.functions.getUserToken();
      let customHeaders = new Headers();
      customHeaders.append('Content-Type', "application/json");
      const requestOptions: RequestOptionsArgs = { headers: customHeaders };
      let userData = {"newUserEmail": usuario.email, 'newUserName': usuario.username + '@' + usuario.base};
      await this.http.post(`https://0udxzzw4hg.execute-api.us-east-1.amazonaws.com/default/OSExterno-createUser?auth=${token}`, userData, requestOptions).toPromise();
    }

    allUsers = allUsers.find((item) => item.base == database);
    if (!allUsers) {
      usuario.username = usuario.username + '@' + usuario.base;
      await this.db.database.app.database(this.functions.getBaseURL()).ref(`/usuarios`).push(usuario);
      this.usuarios = [];

      this.message('Usuário criado com sucesso!', 'success', 'Sucesso', 3000);
      return true;
    } else {
      this.message('Já existe um usuário criado este e-mail!');
      return false;
    }
  }

  async getUserByName(name) {
    if (this.tecUsers.length == 0) {
      await this.getTecUsers();
    }

    let i = 0;
    let data = undefined;
    let found = false;
    while (i < this.tecUsers.length && !found) {
      if (this.tecUsers[i].nome == name) {
        data = this.tecUsers[i];
        found = true;
      }
      i++;
    }

    return data;
  }

  async getUserById(id): Promise<User> {
    let userReturn: User = undefined;
    let data = await this.http.get(`${this.functions.getBaseURL()}/usuarios/${id}/.json`).toPromise();

    userReturn = JSON.parse(data['_body']);
    userReturn.id = id;
    return userReturn;
  }

  async update(usuario) {
    await this.db.database.app.database(this.functions.getBaseURL()).ref(`/usuarios/${usuario.id}`).update(usuario);
    this.usuarios = [];
    this.tecUsers = [];
  }

  public removerUsuario(usuario){
    if (confirm('Deseja excluir o usuário "' + usuario.username + '"')){
      this.db.database.app.database(this.functions.getBaseURL()).ref(`/usuarios/${usuario.id}`).remove();    
      this.usuarios = [];
      return true;
    } else {
      return false;
    }
  }

  getDataBase() {
    let base = JSON.parse(localStorage.getItem("system-base"));
    if (!base) {
      this.router.navigate(['/login']);
      return
    }

    return base.base;
  }

  login(email, pwd) {
    this.afAuth.auth.signInWithEmailAndPassword(email.value, pwd.value)
                    .then(ok => {

                      pwd.value = '';
                      email.value = '';
                      localStorage.setItem('isLoggedin', 'true');
                      this.router.navigate(["/memory"]);
                    }).catch(function(error) {
                      // Handle Errors here.
                      var errorCode = error.code;
                      var errorMessage = error.message;
                      switch (errorCode) {
                        case 'auth/wrong-password': {
                          alert('Senha inválida.');
                          return
                        }
                        case 'auth/invalid-email': {
                          alert(`Usuário inválido.\n${email.value}\n\n${errorMessage}`);
                          return
                        }
                        case 'auth/user-disabled': {
                          alert('Usuário desabilitado.');
                          return
                        }
                        case 'auth/user-not-found': {
                          alert(`Usuário não encontrado.\n${email.value}\n\n${errorMessage}`);
                          return
                        }
                        default: alert('Erro: ' + errorMessage);
                      }
                      console.log(error);
                    });
  }

  async logout() {
    localStorage.removeItem('isLoggedin');
    localStorage.removeItem('system-base');
    this.clearData();
    await this.afAuth.auth.signOut();
  }

  clearData() {
    this.fireUser = undefined;
    this.userData = undefined;
    this.usuarios = [];

    //this.osService.clearList();
  }

  public async getFireUserData() {
    if (!this.fireUser || this.fireUser == null) {
      this.fireUser = await this.afAuth.authState.pipe(first()).toPromise();
    }
    return this.fireUser;
  }

  async getUserFromDataBase() {
    if (!this.userData) {
      let fireUser = await this.getFireUserData();
      if (!fireUser) {return}
      let users = await this.getUsers();
      let base = this.getDataBase();

      users.forEach((user) => {
        if (user.base == base && user.email == fireUser.email) {
          this.userData = user;
        }
      });
    }

    return this.userData;
  }

  async getActiveUsers() {
    let activeUser = await this.getUsers();

    return activeUser.filter((user) => user.ativo);
  }

  async getUsers() {
    if (this.usuarios.length == 0) {
      //let token = await this.getUserToken();
       let data = await this.http.get(`${this.functions.getBaseURL()}/usuarios/.json`).toPromise();

      let base = this.getDataBase();
      let list = this.convertObjToArray(data);
      this.usuarios = [];
      list.forEach((item) => {
        if (item.base == base) {
          this.usuarios.push(item);
        }
      })
    }
    return JSON.parse(JSON.stringify(this.usuarios));
  }

  async getTecUsers() {
    if (this.tecUsers.length == 0) {
      let users = await this.getUsers();
      this.tecUsers = [];
      users.forEach((item) => {
        if (item.tecnico) {
          this.tecUsers.push(item);
        }
      });

      this.tecUsers.sort((a,b) => {
        if (a.nome < b.nome) {
          return -1
        } else {
          if (a.nome > b.nome) {
            return 1
          } else {
            return 0
          }
        }
      })
    }

    return Object.assign([], this.tecUsers);
  }

  async getTecUserColor(name) {
    if (this.tecUsers.length == 0) {
      await this.getTecUsers();
    }

    let i = 0;
    let found = false;
    let color = '#8293ff';
    while (i < this.tecUsers.length && !found) {
      if (this.tecUsers[i].nome == name) {
        color = this.tecUsers[i].color;
        found = true;
      }
      i++;
    }

    return color;
  }

  convertObjToArray(obj) {
    let objs = JSON.parse(obj['_body']);
    if (obj && objs) {
      return Object.keys(objs).map( (item) => {
              let data = objs[item];
              data['id'] = item;
              return data;
            });
    }
    return [];
  }

  async saveColumnsConfigOpen (user, fliterUser) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/abertas/${user}/.json?auth=${token}`, fliterUser).toPromise();
  }

  async loadColumnsConfigOpen (user) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/abertas/${user}/.json?auth=${token}`).toPromise();    
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async saveColumnsConfigCli (user, fliterUser) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/clientes/${user}/.json?auth=${token}`, fliterUser).toPromise();
  }

  async loadColumnsConfigCli (user) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/clientes/${user}/.json?auth=${token}`).toPromise();    
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async saveColumnsConfigProspect (user, fliterUser) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/prospect/${user}/.json?auth=${token}`, fliterUser).toPromise();
  }

  async loadColumnsConfigProspect (user) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/prospect/${user}/.json?auth=${token}`).toPromise();    
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async saveColumnsConfigOrc(user, fliterUser) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/orcamento/${user}/.json?auth=${token}`, fliterUser).toPromise();
  }

  async loadColumnsConfigOrc(user) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/orcamento/${user}/.json?auth=${token}`).toPromise();
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async saveColumnsConfigOrcAproved(user, fliterUser) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/orcamento-aprovado/${user}/.json?auth=${token}`, fliterUser).toPromise();
  }

  async loadColumnsConfigOrcAproved(user) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/orcamento-aprovado/${user}/.json?auth=${token}`).toPromise();
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

  async saveColumnsConfigOrcRejected(user, fliterUser) {
    let token = await this.functions.getUserToken();
    await this.http.put(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/orcamento-rejeitado/${user}/.json?auth=${token}`, fliterUser).toPromise();
  }

  async loadColumnsConfigOrcRejected(user) {
    let token = await this.functions.getUserToken();
    let data = await this.http.get(`${this.functions.getBaseURL()}/usuariosConfig/filtroColunas/orcamento-rejeitado/${user}/.json?auth=${token}`).toPromise();
    let list = JSON.parse(data['_body']) ? JSON.parse(data['_body']) : [];

    return list;
  }

}
